export default function formatDate(
  input = "now",
  format = null,
  langcode = "fr-FR",
  timeZone = "Europe/Paris"
) {
  const date = input === "now" ? new Date() : new Date(input)

  switch (format) {
    case "short-yearless":
      return date.toLocaleDateString(langcode, {
        day: "2-digit",
        month: "short",
        timeZone,
      })
    case "short":
      return date.toLocaleDateString(langcode, {
        day: "2-digit",
        month: "short",
        year: "numeric",
        timeZone,
      })
    case "d/m":
      return [
        date.toLocaleDateString(langcode, {
          day: "2-digit",
          timeZone,
        }),
        date.toLocaleDateString(langcode, {
          month: "2-digit",
          timeZone,
        }),
      ].join("/")
    case "d/m/Y":
      return [
        date.toLocaleDateString(langcode, {
          day: "2-digit",
          timeZone,
        }),
        date.toLocaleDateString(langcode, {
          month: "2-digit",
          timeZone,
        }),
        date.toLocaleDateString(langcode, {
          year: "numeric",
          timeZone,
        }),
      ].join("/")
    case "time":
      return date
        .toLocaleDateString(langcode, {
          hour: "2-digit",
          minute: "2-digit",
          timeZone,
        })
        .split(" ")[1]
    default:
      return date.toLocaleDateString(langcode, {
        day: "2-digit",
        month: "long",
        year: "numeric",
        timeZone,
      })
  }
}
