import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"

import Small16by10 from "../Images/Small16by10"

const ArticleListItem = ({ content }) => (
  <div className="article-list-item relative">
    <div className="list-item-image">
      <Link to={content.path.alias} className="block overflow-hidden">
        {content.relationships.image ? (
          <Img
            fluid={content.relationships.image.localFile.childImageSharp.fluid}
            alt={content.title}
            className="transform hover:scale-110 transition-all duration-500"
          />
        ) : (
          <Small16by10
            alt={content.title}
            className="transform hover:scale-110 transition-all duration-500"
          />
        )}
      </Link>
    </div>
    <div className="list-item-content py-4 px-2 text-center lg:text-left bg-white border-b md:border-b-0 border-gray-light">
      {content.relationships.tags && (
        <Link
          to={content.relationships.tags.path.alias}
          className="text-secondary uppercase text-sm font-semibold mb-1"
        >
          {content.relationships.tags.name}
        </Link>
      )}
      <div className="text-lg font-semibold">
        <Link to={content.path.alias} className="text-secondary">
          {content.title}
        </Link>
      </div>
    </div>
  </div>
)

ArticleListItem.propTypes = {
  content: PropTypes.object.isRequired,
}

export default ArticleListItem
