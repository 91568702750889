import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Small16by10 from "../Images/Small16by10"
import Truncate from "../Truncate"
import { useState } from "react"

const ArticlesPreview = ({ name, hideBlock }) => {
  const data = useStaticQuery(graphql`
    {
      articles: allNodeArticle(
        limit: 4
        filter: {
          status: { eq: true }
          sticky: { eq: true }
          promote: { eq: true }
        }
        sort: { fields: created, order: DESC }
      ) {
        edges {
          node {
            title
            body {
              summary
              value
            }
            path {
              alias
            }
            relationships {
              image: field_image {
                localFile {
                  childImageSharp {
                    fluid(
                      maxWidth: 846
                      maxHeight: 490
                      cropFocus: CENTER
                      quality: 100
                    ) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              tags: field_tags {
                tid: drupal_internal__tid
                name
                path {
                  alias
                }
              }
            }
          }
        }
      }
    }
  `)

  const first = 0
  const last = data.articles.edges.length

  const [active, setActive] = useState(0)

  const next = () => {
    let next = active + 1
    setActive(next >= last ? first : next)
  }

  const previous = () => {
    let previous = active + 1
    setActive(previous <= first ? last : previous)
  }

  const navClickHandler = index => {
    if (active !== index) {
      setActive(index)
    }
  }

  const navKeyDownHandler = (event, index) => {
    if (event.key === "Enter") {
      navClickHandler(index)
    }
  }

  if (!last) {
    hideBlock(name)
    return <></>
  }

  return (
    <div className="relative">
      <div className="lg:absolute top-0 right-0 lg:w-1/2 xl:w-2/5 p-8">
        <h2 className="title h2 leading-none mb-0 text-center lg:text-left">
          <span className="block font-handwritten font-normal text-4xl text-gray-dark">
            Les actualités
          </span>
          <small className="text-primary uppercase">à la une</small>
        </h2>
      </div>

      <ul
        className="nav-dots hidden lg:inline-flex absolute top-36 right-0 p-8 xl:p-12 z-10"
        role="presentation"
      >
        {data.articles.edges.map(({ node }, index) => (
          <li key={index} className={`nav-index-${index} p-1`}>
            <span
              className={`block w-2 h-2 rounded-full ${
                index === active ? `bg-secondary` : `bg-white`
              } text-transparent overflow-hidden`}
              tabIndex={0}
              role="button"
              onClick={() => navClickHandler(index)}
              onKeyDown={event => navKeyDownHandler(event, index)}
            >
              {node.title}
            </span>
          </li>
        ))}
      </ul>

      <div className="block lg:hidden absolute left-1/2 top-48 bottom-0 w-screen transform -translate-x-1/2 bg-primary">
        &nbsp;
      </div>

      {data.articles.edges.map(({ node }, index) => (
        <div
          key={index}
          className={`article-list-item ${
            index === active ? `flex` : `hidden`
          } flex-col lg:flex-row`}
        >
          <div className="list-item-image w-full lg:w-1/2 xl:w-3/5 z-10">
            <Link to={node.path.alias} className="block overflow-hidden">
              {node.relationships.image ? (
                <Img
                  fluid={
                    node.relationships.image.localFile.childImageSharp.fluid
                  }
                  alt={node.title}
                  className={`${
                    node.relationships.banner ? `block xl:hidden` : ``
                  } transform hover:scale-110 transition-all duration-500`}
                />
              ) : (
                <Small16by10
                  alt={node.title}
                  className="transform hover:scale-110 transition-all duration-500"
                />
              )}
            </Link>
          </div>
          <div className="list-item-content lg:w-1/2 xl:w-2/5 lg:pt-36">
            <div className="px-0 lg:px-8 py-8 xl:p-12 bg-primary text-white relative">
              <div className="hidden lg:block absolute inset-y-0 right-0 bg-primary lg:w-11/10 xl:w-6/5">
                &nbsp;
              </div>
              <div className="relative">
                {node.relationships.tags && (
                  <div className="mb-4">
                    <Link
                      to={`/actualites${node.relationships.tags.path.alias}`}
                      className="bg-white p-1 font-semibold text-secondary text-xs uppercase hover:bg-secondary hover:text-white"
                    >
                      {node.relationships.tags.name}
                    </Link>
                  </div>
                )}
                <div className="title h3 title-underlined mb-4">
                  <Link to={node.path.alias} className="hover:text-secondary">
                    {node.title}
                  </Link>
                </div>
                {node.body && (
                  <Truncate
                    className="content mb-20 lg:mb-12"
                    limit={200}
                    html={
                      node.body.summary.length > 0
                        ? node.body.summary
                        : node.body.value
                    }
                  />
                )}
              </div>
              <div className="absolute left-1/2 lg:left-auto lg:right-0 bottom-8 lg:bottom-0 lg:px-12 transform -translate-x-1/2 lg:translate-x-0 lg:translate-y-1/2 z-10">
                <Link
                  to={node.path.alias}
                  className="button button-secondary whitespace-nowrap"
                >
                  Lire la suite
                </Link>
              </div>
            </div>
          </div>
        </div>
      ))}

      {last > 1 && (
        <div className="nav-prevnext flex justify-between absolute bottom-0 inset-x-0 lg:hidden text-white text-2xl px-0 lg:px-8 py-8">
          <button className="prev" onClick={previous}>
            <div className="icon">
              <span class="fas fa-arrow-left"></span>
            </div>
          </button>
          <button className="next" onClick={next}>
            <div className="icon">
              <span class="fas fa-arrow-right"></span>
            </div>
          </button>
        </div>
      )}
    </div>
  )
}

export default ArticlesPreview
