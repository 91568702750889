import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import ArticleListItem from "../ListItems/ArticleListItem"
import EventListItem from "../ListItems/EventListItem"

const MixedPreview = ({ name, hideBlock, limit }) => {
  const data = useStaticQuery(graphql`
    {
      articles: allNodeArticle(
        limit: 4
        filter: {
          status: { eq: true }
          sticky: { eq: false }
          promote: { eq: true }
        }
        sort: { fields: created, order: DESC }
      ) {
        nodes {
          internal {
            type
          }
          title
          path {
            alias
          }
          relationships {
            image: field_image {
              localFile {
                childImageSharp {
                  fluid(
                    maxWidth: 332
                    maxHeight: 208
                    cropFocus: CENTER
                    quality: 100
                  ) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            tags: field_tags {
              tid: drupal_internal__tid
              name
              path {
                alias
              }
            }
          }
        }
      }
      events: allNodeEvenement(
        sort: {
          fields: [field_date___value, field_date___end_value]
          order: ASC
        }
        filter: { is_forthcoming: { eq: true }, status: { eq: true } }
      ) {
        nodes {
          title
          dates: field_date {
            start: value(formatString: "YYYY-MM-DDTHH:mm:ssZ", locale: "fr")
            end: end_value(formatString: "YYYY-MM-DDTHH:mm:ssZ", locale: "fr")
          }
          place: field_emplacement
          relationships {
            image: field_image {
              localFile {
                childImageSharp {
                  fluid(
                    maxWidth: 332
                    maxHeight: 208
                    cropFocus: CENTER
                    quality: 100
                  ) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          path {
            alias
          }
          internal {
            type
          }
        }
      }
    }
  `)

  let mixedContent = []

  if (data.events.nodes.length > 0) {
    mixedContent = mixedContent.concat(data.events.nodes)
  }

  if (data.articles.nodes.length > 0) {
    mixedContent = mixedContent.concat(data.articles.nodes)
  }

  mixedContent = mixedContent.slice(0, limit)

  if (!mixedContent.length) {
    hideBlock(name)
    return <></>
  }

  return (
    <div className="relative">
      <h2 className="title h2 leading-none text-center lg:text-left">
        <span className="block font-handwritten font-normal text-4xl text-gray-dark">
          Vivre à Coucy&nbsp;
        </span>
        <small className="uppercase text-primary">en ce moment</small>
      </h2>
      <div className="columns grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-8">
        {mixedContent.map((node, index) => (
          <div key={index} className={`column mixed`}>
            {node.internal.type === "node__article" ? (
              <ArticleListItem content={node} />
            ) : (
              <EventListItem content={node} />
            )}
          </div>
        ))}
      </div>
      <div className="more-links mt-12 flex flex-col lg:flex-row justify-center gap-8">
        <Link
          to="/actualites"
          className="button button-primary justify-center w-full lg:w-1/4"
        >
          Voir toute l'actualité
        </Link>
        <Link
          to="/evenements"
          className="button button-primary justify-center w-full lg:w-1/4"
        >
          Voir tout l'agenda
        </Link>
        <Link
          to="/albums-photos"
          className="button button-primary justify-center w-full lg:w-1/4"
        >
          Voir tous les albums
        </Link>
      </div>
    </div>
  )
}

export default MixedPreview
