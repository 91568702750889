import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { iconInvisibleText } from "../../Utils/functions"
import MenuLink from "../MenuLink"

const ServicesMenu = ({ name, hideBlock }) => {
  const data = useStaticQuery(graphql`
    {
      servicesMenu: allMenuLinkContentMenuLinkContent(
        filter: { enabled: { eq: true }, menu_name: { eq: "services" } }
        sort: { fields: weight, order: ASC }
      ) {
        nodes {
          drupal_parent_menu_item
          drupal_id
          title
          link {
            uri
            options {
              attributes {
                class
                target
                data_has_icon
                data_icon
                data_text_invisible
              }
            }
          }
        }
      }
    }
  `)

  if (!data.servicesMenu.nodes.length) {
    hideBlock(name)
    return <></>
  }

  return (
    <>
      <ul id="services--menu">
        <div className="flex flex-col md:flex-row flex-wrap md:items-center justify-center md:-mx-4 md:-mb-8 text-left md:text-center">
          {data.servicesMenu.nodes.map((menu, index) => {
            const attributes = menu.link.options
              ? menu.link.options.attributes
              : null

            return (
              <li className="menu-item px-4 mb-4 lg:mb-8" key={index}>
                <MenuLink
                  internalId={menu.link.uri}
                  className="menu-link flex sm:inline-flex items-center md:px-8 md:py-6 text-lg text-white hover:text-secondary"
                >
                  {attributes && attributes.data_has_icon ? (
                    <>
                      <div className="icon mr-6 text-4xl">
                        <i className={attributes.data_icon}>
                          <span className="hidden">{menu.title}</span>
                        </i>
                      </div>
                      <div>
                        {attributes.data_text_invisible
                          ? iconInvisibleText(menu.title)
                          : menu.title}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="icon mr-6 text-4xl">
                        <i className="fas fa-link">
                          <span className="hidden">{menu.title}</span>
                        </i>
                      </div>
                      <div>{menu.title}</div>
                    </>
                  )}
                </MenuLink>
              </li>
            )
          })}
        </div>
      </ul>
    </>
  )
}

export default ServicesMenu
