import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import Small16by10 from "../Images/Small16by10"
import formatDate from "../../Utils/formatDate"

const EventListItem = ({ content }) => {
  return (
    <div className="event-list-item relative">
      <div className="list-item-image">
        <Link to={content.path.alias} className="block overflow-hidden">
          {content.relationships.image ? (
            <Img
              fluid={
                content.relationships.image.localFile.childImageSharp.fluid
              }
              alt={content.title}
              className="transform hover:scale-110 transition-all duration-500"
            />
          ) : (
            <Small16by10
              alt={content.title}
              className="transform hover:scale-110 transition-all duration-500"
            />
          )}
        </Link>
      </div>
      <div className="list-item-content py-4 px-2 text-center lg:text-left bg-white border-b md:border-b-0 border-gray-light">
        <div className="text-secondary text-sm font-semibold mb-1">
          <span>
            {content.dates.end !== content.dates.start
              ? `Du ${formatDate(
                  content.dates.start,
                  "short-yearless"
                )} au ${formatDate(content.dates.end, "short")}`
              : `Le ${formatDate(content.dates.start)}`}
          </span>
          {content.place && (
            <>
              <span className="separator mx-1">-</span>
              <span>{content.place}</span>
            </>
          )}
        </div>
        <div className="text-lg font-semibold">
          <Link to={content.path.alias} className="text-secondary">
            {content.title}
          </Link>
        </div>
      </div>
    </div>
  )
}

EventListItem.propTypes = {
  content: PropTypes.object.isRequired,
}

export default EventListItem
