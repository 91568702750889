import React, { useState } from "react"
import Layout from "../components/Layout"
import Metas from "../components/Metas"
import Block from "../components/Block"
import ArticlesPreview from "../components/Blocks/ArticlesPreview"
import MixedPreview from "../components/Blocks/MixedPreview"
import ServicesMenu from "../components/Menus/ServicesMenu"
import ImagesGalleriesPreview from "../components/Blocks/ImagesGalleriesPreview"
import { classNames } from "../Utils/functions"

const IndexPage = () => {
  const [hiddenBlocks, setHiddenBlocks] = useState([])

  const hideBlock = name => {
    if (!hiddenBlocks.includes(name)) {
      setHiddenBlocks([...hiddenBlocks, name])
    }
  }

  return (
    <Layout isHome>
      <Metas
        title="Accueil"
        description="Bienvenue sur le site de la Commune de Coucy. Retrouvez tous les services et démarches, les informations pratiques, les actualités et événements de la commune des Ardennes"
      />

      <Block
        id="articles-preview--block"
        className={classNames(
          hiddenBlocks.includes("articles-preview") && "hidden"
        )}
        container
        section
      >
        <ArticlesPreview name="articles-preview" hideBlock={hideBlock} />
      </Block>

      <Block
        id="mixed-preview--block"
        className={classNames(
          hiddenBlocks.includes("mixed-preview") && "hidden"
        )}
        container
        section
      >
        <MixedPreview name="mixed-preview" hideBlock={hideBlock} limit={4} />
      </Block>

      <Block
        id="services-menu--block"
        className={classNames(
          hiddenBlocks.includes("services-menu") && "hidden",
          "relative py-8 lg:py-16"
        )}
        container
        section
      >
        <div className="bg-primary text-white text-center px-8 lg:px-0 py-6">
          <h2 className="title h2 inline-block relative">
            <span className="block font-handwritten font-normal text-4xl">
              En un clic !
            </span>
            <small className="uppercase border-b border-secondary pb-2">
              Accès rapides
            </small>
            <span className="icon hidden lg:block absolute left-full top-full ml-2 -mt-4 text-secondary text-4xl font-normal">
              <i className="far fa-hand-pointer"></i>
            </span>
          </h2>
          <ServicesMenu name="services-menu" hideBlock={hideBlock} />
        </div>
      </Block>

      <Block
        id="image-galleries-preview--block"
        className={classNames(
          hiddenBlocks.includes("image-galleries-preview") && "hidden",
          "relative py-8 lg:py-16"
        )}
        container
        section
      >
        <div className="absolute top-1/3 bottom-0 inset-x-0 bg-primary-light bg-opacity-10">
          &nbsp;
        </div>
        <ImagesGalleriesPreview
          name="image-galleries-preview"
          hideBlock={hideBlock}
        />
      </Block>

      <Block
        id="services-menu--block"
        className="py-8 lg:py-16 bg-primary-light bg-opacity-10"
        container
        section
      >
        <div className="relative bg-primary text-white text-center lg:text-left px-8 py-6 overflow-hidden">
          <div
            class="hidden lg:block absolute top-1/2 right-0 transform -translate-y-1/2 rotate-45 text-white text-opacity-20 leading-none"
            style={{ fontSize: "500px" }}
            aria-hidden="true"
          >
            <i class="far fa-envelope" />
          </div>
          <div className="relative">
            <h2 className="title h2 inline-block relative">
              <span className="block font-handwritten font-normal text-4xl">
                Pour ne rien louper
              </span>
              <small className="uppercase">
                Inscrivez vous aux alertes mails
              </small>
            </h2>
            <div class="grid grid-cols-1 lg:grid-cols-3 gap-6">
              <div class="lg:col-span-2">
                <div>
                  <p>
                    <strong>
                      Vous souhaitez &ecirc;tre inform&eacute; d&rsquo;un
                      probl&egrave;me d&rsquo;intemp&eacute;rie, d&rsquo;une
                      fermeture de route ou d&rsquo;&eacute;cole, ou d&rsquo;un
                      &eacute;v&egrave;nement particulier ?
                    </strong>
                  </p>
                  <p>La commune vous informe par mail.</p>
                </div>
              </div>
              <div class="text-center">
                <a
                  href="/inscription-aux-alertes-mails"
                  class="button button-secondary hover-white"
                >
                  je m&apos;inscris
                </a>
              </div>
            </div>
          </div>
        </div>
      </Block>
    </Layout>
  )
}

export default IndexPage
