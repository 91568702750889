import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const ImagesGalleriesPreview = ({ name, hideBlock }) => {
  const data = useStaticQuery(graphql`
    {
      imagesGalleries: allNodeAlbumPhoto(
        filter: { status: { eq: true } }
        sort: { order: DESC, fields: [created] }
        limit: 1
      ) {
        nodes {
          title
          path {
            alias
          }
          images: field_images {
            alt
            width
            height
          }
          relationships {
            largeImages: field_images {
              localFile {
                childImageSharp {
                  fluid(
                    maxWidth: 993
                    maxHeight: 713
                    cropFocus: CENTER
                    quality: 100
                  ) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            images: field_images {
              localFile {
                childImageSharp {
                  fluid(
                    maxWidth: 492
                    maxHeight: 353
                    cropFocus: CENTER
                    quality: 100
                  ) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const filteredData = data.imagesGalleries.nodes.filter(node => {
    return node.relationships.images.length >= 2
  })

  if (!filteredData.length) {
    hideBlock(name)
    return <></>
  }

  return (
    <div className="relative">
      <h2 className="title h2 leading-none text-center lg:text-left">
        <span className="block font-handwritten font-normal text-4xl text-gray-dark">
          #Coucy&nbsp;
        </span>
        <small className="uppercase text-primary">Retour en images</small>
      </h2>

      {filteredData.map((node, index) => (
        <div key={index}>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-0 lg:gap-8">
            <Link
              to={node.path.alias}
              title={node.title}
              className="block first-image lg:col-span-2 overflow-hidden"
            >
              <Img
                fluid={
                  node.relationships.largeImages[0].localFile.childImageSharp
                    .fluid
                }
                className="transform hover:scale-110 transition-all duration-500"
              />
            </Link>
            <div className="images flex flex-row lg:flex-col lg:justify-between">
              <Link
                to={node.path.alias}
                title={node.title}
                className="block image mb-4 lg:mb-0 w-1/2 lg:w-full overflow-hidden"
              >
                <Img
                  fluid={
                    node.relationships.images[1].localFile.childImageSharp.fluid
                  }
                  className="transform hover:scale-110 transition-all duration-500"
                />
              </Link>
              <Link
                to={node.path.alias}
                title={node.title}
                className="block image w-1/2 lg:w-full overflow-hidden"
              >
                <Img
                  fluid={
                    node.relationships.images[2].localFile.childImageSharp.fluid
                  }
                  className="transform hover:scale-110 transition-all duration-500"
                />
              </Link>
            </div>
          </div>
        </div>
      ))}

      <div className="more-links mt-12 text-center">
        <Link
          to="/albums-photos"
          className="button button-primary justify-center"
        >
          Voir tous les albums
        </Link>
      </div>
    </div>
  )
}

export default ImagesGalleriesPreview
